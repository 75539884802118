import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layoutEs";
import Seo from "../../components/seoEs";
import Footer from "../../components/footer/footerEs";

import snickersLogo from "../../images/snickers-hunger-insurance-es.png";

const PromotionClose = () => {
  return (
    <Layout>
      <Seo title="Home | Seguros Snickers" lang="es" />

      <section className="agegate-hldr promotion-close pt d-flex justify-content-between flex-column align-items-center pt-5">
        <div className="d-flex flex-column justify-content-center align-items-center h-100 px-md-5 mx-md-5 px-3">
          <Link to="/">
            <figure className="logo">
              <img
                src={snickersLogo}
                alt="Snickers Logo"
                className="img-fluid"
              />
            </figure>
          </Link>
          <h1 className="text-white">
            Oops, se han agotado nuestras pólizas de seguros, para la próxima
            cómete un Snickers y no llegues tan tarde. Visita nuestros sitios y
            mantente atento a las próximas noticias.
          </h1>
          <div>
            <a
              href="https://es-la.facebook.com/SNICKERSMexico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fa fa-facebook-f"
                style={{ fontSize: "48px", color: "white" }}
              ></i>
              <span className="visually-hidden">(opens in a new window)</span>
            </a>
            <a
              href="https://www.instagram.com/snickersmexico/?hl=es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fa fa-instagram mx-4"
                style={{ fontSize: "48px", color: "white" }}
              ></i>
              <span className="visually-hidden">(opens in a new window)</span>
            </a>
            <a
              href="https://twitter.com/SNICKERSMexico?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fa fa-twitter"
                style={{ fontSize: "48px", color: "white" }}
              ></i>
              <span className="visually-hidden">(opens in a new window)</span>
            </a>
          </div>
        </div>
        <Footer />
      </section>
    </Layout>
  );
};

export default PromotionClose;
